import { QueryParams, SortDirection } from "@/data/types";

/**
 * Convert a data field name to JSON notation.
 *
 * @param field - A field name to parse to JSON notation.
 * @param toJson - A data parsing function that is originally
 * intended for parsing data from frontend representation to the
 * backend JSON representation. It is used here for mapping
 * the notation of the field name/key to JSON.
 */
function convertFieldToJsonNotation<Data, DataJSON>(
  field: keyof Data,
  // eslint-disable-next-line no-unused-vars
  toJson: (data: Data) => DataJSON
) {
  const fakeData = {
    // We need to have some placeholder value here, since undefined properties
    // are filtered out while parsing to json. But we only care about the key, not the value.
    [field]: "PLACEHOLDER",
    // The type assertion here is necessary, because the value that gets passed
    // at runtime for the generic type data does not necessarily allow for string values,
    // at least TypeScript cannot know this at compile time. But since we don't care
    // about the value for parsing the key it doesn't matter here.
  } as unknown as Data;

  const fakeJson = toJson(fakeData);

  const jsonKey = Object.keys(fakeJson)[0];

  return jsonKey;
}

/**
 * Parse query params from frontend to backend/json format.
 *
 * @param params - Query params.
 * @param toJson - Function to parse an entity into json format.
 */
export function parseQueryParams<Data, DataJSON>(
  params: QueryParams<Data>,
  // eslint-disable-next-line no-unused-vars
  toJson: (data: Data) => DataJSON
) {
  const { page, pageSize, ids, fields, query, sortBy, sortDir, workspace } =
    params;

  return {
    offset:
      page !== undefined && pageSize !== undefined
        ? page * pageSize
        : undefined,
    limit: pageSize !== undefined ? pageSize : undefined,
    id__in: ids?.length > 0 ? ids?.join(",") : undefined,
    fields:
      fields?.length > 0
        ? fields
            .map(f => convertFieldToJsonNotation<Data, DataJSON>(f, toJson))
            .join(",")
        : undefined,
    search: query || undefined,
    ordering: sortBy
      ? `${sortDir === SortDirection.DESCENDING ? "-" : ""}${String(sortBy)}`
      : undefined,
    workspace,
  };
}
