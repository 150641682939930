/* eslint-disable no-unused-vars */
export enum Status {
  UNDECIDED = "UNDECIDED",
  ALLOWED_ALL = "ALLOWED_ALL",
  REJECTED_ALL = "REJECTED_ALL",
  ALLOWED_SOME = "ALLOWED_SOME",
}

export enum View {
  BANNER = "BANNER",
  FBA = "FBA",
  PREFERENCES = "PREFERENCES",
}

export enum ActionType {
  ALLOW_ALL = "ALLOW_ALL",
  REJECT_ALL = "REJECT_ALL",
  ALLOW_SOME = "ALLOW_SOME",
  SHOW_OPTIONS = "SHOW_OPTIONS",
  HIDE_OPTIONS = "HIDE_OPTIONS",
}

export enum Category {
  // MARKETING = "MARKETING",
  // PERSONALIZATION = "PERSONALIZATION",
  ANALYTICS = "ANALYTICS",
}

export interface Action {
  type: ActionType;
  categories?: Array<Category>;
}

export interface State {
  status: Status;
  categories: Array<Category>;
  view: View;
}
/* eslin-enable no-unused-vars */
