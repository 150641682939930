/**
 * Local storage client.
 *
 * Client to interact with the local storage of a user's browser.
 */

const storage = {
  /**
   * Save a value to localStorage.
   *
   * @param key - The key to store the value under.
   * @param value - The value to store in localStorage.
   * @param serialize - Whether the input needs to be serialized.
   *
   * @returns - An error if there was one.
   */
  save: (key: string, value: any, serialize: boolean = true): Error | null => {
    let error = null;

    try {
      const valueString = serialize ? JSON.stringify(value) : value;
      window.localStorage.setItem(key, valueString);
    } catch (err) {
      console.warn(err);
      error = err;
    }

    return error;
  },
  /**
   * Load a value from localStorage by key.
   *
   * @param key - The key to look for in localStorage.
   * @param fallback - A fallback value to return, if key is not found.
   *
   * @returns - Either the value found in localStorage or the fallback.
   */
  load: (key: string, fallback: any = null): any => {
    let value = fallback;

    try {
      const valueString = window.localStorage.getItem(key);
      if (valueString) value = JSON.parse(valueString);
    } catch (err) {
      console.warn(err);
    }

    return value;
  },
  /**
   * Delete a value from localStorage.
   *
   * @param key - The key of the value to delete.
   *
   * @returns - An error if there was one.
   */
  delete: (key: string): Error | null => {
    let error = null;

    try {
      window.localStorage.removeItem(key);
    } catch (err) {
      console.warn(err);
      error = err;
    }

    return error;
  },
};

export default storage;
