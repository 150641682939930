import { useRouter } from "next/router";

const publicPaths = [
  "/login",
  "/login-error",
  "/canny-sso",
  "/reset-password",
  "/reset-password/[uid]/[token]",
  "/sign-up",
  "/sign-up/[uid]",
  "/sign-up/[uid]/[token]",
  "/projects/public/[projectId]",
];

/**
 * Hook for checking if current route path is a public path.
 */
const useIsPublicPath = () => {
  const router = useRouter();
  const isPublicPath = publicPaths.includes(router.pathname);

  return isPublicPath;
};

export default useIsPublicPath;
