export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type WithOptional<T, K extends keyof T> = Omit<T, K> &
  Partial<Pick<T, K>>;

/**
 * Unique identifier for entity records.
 */
export type Id = number;

/**
 * Paginated records list page.
 */
export type ListPage<T> = {
  /**
   * Total number of records.
   */
  count: number;
  /**
   * URL to get the previous page.
   */
  previous?: string;
  /**
   * URL to get the next page.
   */
  next?: string;
  /**
   * Actual list of record results.
   */
  results: Array<T>;
};

export enum SortDirection {
  // eslint-disable-next-line no-unused-vars
  ASCENDING = "asc",
  // eslint-disable-next-line no-unused-vars
  DESCENDING = "desc",
}

export type SearchPage<T> = {
  docCount: number;
  hits: Array<T>;
  total: { value: number; relation: string };
};

export interface PaginationParams {
  /**
   * Page of a list.
   */
  page?: number;
  /**
   * Page size of a list.
   */
  pageSize?: number;
}

export type SortParams<T> = {
  /**
   * Sort list by a property.
   */
  sortBy?: keyof T;
  /**
   * Direction of sorting.
   */
  sortDir?: SortDirection;
};

export type QueryParams<T> = PaginationParams &
  SortParams<T> & {
    /**
     * Limit list to only include certain ids.
     */
    ids?: Array<Id>;
    /**
     * Select only specific fields of the requested entities.
     */
    fields?: Array<keyof T>;
    /**
     * A search query to filter a list by.
     */
    query?: string;
    /**
     * Filter results by workspace id.
     */
    workspace: Id;
  };

/* eslint-disable no-unused-vars */
export enum StatusCode {
  PENDING = 1,
  ANALYSING_DOCUMENT = 2,
  EXTRACTING_TEXT = 3,
  APPLYING_LATERAL_MAGIC = 4,
  UNABLE_TO_FETCH_PDF = 95,
  QUALITY_ISSUES = 96,
  SUPPORT_REVIEWING = 97,
  QUOTA_EXCEEDED = 98,
  FAILED = 99,
  AVAILABLE = 100,
}
/* eslint-enable no-unused-vars */

/* eslint-disable no-unused-vars */
export enum SubscriptionStatus {
  FREE = "FREE",
  SUBSCRIBED = "SUBSCRIBED",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  ENDED = "ENDED",
}
/* eslint-enable no-unused-vars */
