import { isWindowAvailable } from "@/utils";

export const HIGH_LIST_PAGE_SIZE = 1000000;

export const INTERCOM_APP_ID =
  isWindowAvailable() &&
  (window.location.host === "app.lateral.io"
    ? process.env.NEXT_PUBLIC_INTERCOM_APP_ID
    : process.env.NEXT_PUBLIC_INTERCOM_TEST_APP_ID);

export const LateralLinks = {
  WEBSITE: "https://www.lateral.io",
  PRIVACY: "https://www.lateral.io/privacy-policy",
  TERMS: "https://www.lateral.io/terms-of-use",
  DPA: "https://www.lateral.io/dpa",
};

export const REFERENCES_TOOLTIP_TEXT = [
  "Lateral does its best to automatically extract references from the docs you upload.",
  "Before exporting, you can check and edit these, where needed, in the sidebar by clicking",
  "on each document in the table.",
].join(" ");

export const QuotaTooltipsTexts = {
  DIGIDOCS: "This shows the number of total pages you can currently use.",
  OCR: [
    "This shows the number of OCR pages you can currently use. Some",
    "scanned documents require OCR to get the text out of them. This",
    "requires more complex processing.",
  ].join(" "),
};

export const ONLY_SHOW_READY_DOCS_TOGGLE_KEY =
  "lateral-project-ready-only-docs";
