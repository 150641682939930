import { api, usersApi } from "@/clients";
import parseMe from "@/data/auth/parse";
import { Me } from "@/data/auth/types";
import { Id } from "@/data/types";

/**
 * Get current user from whoami
 *
 * @returns - Promise resolving to user.
 */
export const fetchCurrentUser = (): Promise<Me> =>
  usersApi.get(`/whoami/`).then(res => parseMe.fromJSON(res.data));

/**
 * Update an existing user.
 *
 * @param id - Id of the user to update.
 * @param data - Data updates for the user.
 *
 * @returns - Promise resolving to the updated user.
 */
export const update = (
  id: Id,
  { additionalFields }: Partial<Pick<Me, "additionalFields">>
): Promise<Me> =>
  api
    .patch(`/users/${id}/`, parseMe.toJSON({ additionalFields }))
    .then(res => parseMe.fromJSON(res.data));

export default {
  fetchCurrentUser,
  update,
};
