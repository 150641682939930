/* eslint-disable react/display-name */

import React, { forwardRef } from "react";
import classNames from "classnames";
import type * as Polymorphic from "@radix-ui/react-polymorphic";

/**
 * Flexible box component.
 *
 * @example
 * <Box as="section">Lorem ipsum</Box>
 */
const Box = forwardRef(
  ({ as: Component = "div", className, ...props }, forwardedRef) => (
    <Component
      ref={forwardedRef}
      className={classNames("Box", className)}
      {...props}
    />
  )
) as Polymorphic.ForwardRefComponent<"div">;

Box.displayName = "Box";

export default Box;
