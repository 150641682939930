import { Id } from "@/data/types";

const QueryKeys = {
  /**
   * Get query key for all queries related to users.
   */
  all: () => ["users"] as const,
  /**
   * Get query key for currently selected workspace based on URL.
   */
  current: () => ["users", "current"] as const,
  /**
   * Get query key for all workspace detail queries.
   */
  details: () => [...QueryKeys.all(), "detail"] as const,
  /**
   * Get query key for specific workspace detail query.
   *
   * @param id - Id of a workspace.
   */
  detail: (id: Id) => [...QueryKeys.details(), id] as const,
};

export default QueryKeys;
