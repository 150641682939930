/**
 * Promisify `requestAnimationFrame` browser API.
 *
 * @returns - Promise resolving on next frame.
 *
 * @example
 * await nextFrame();
 */
const nextFrame = () =>
  new Promise(resolve => {
    // double invocation because of
    // known Chrome bug
    requestAnimationFrame(() => {
      requestAnimationFrame(resolve);
    });
  });

export default nextFrame;
