export default {
  document_id: 296,
  start_page_idx: 0,
  start_line_idx: 7,
  end_page_idx: 6,
  end_line_idx: 30,
  items: [
    {
      level: 0,
      lines: [
        {
          page_idx: 0,
          par_idx: 9,
          line_idx: 9,
          x_left: 0.0682,
          y_top: 0.3128,
          x_right: 0.1486,
          y_bottom: 0.3218,
          display_text: "1.1 General",
        },
      ],
    },
    {
      level: 0,
      lines: [
        {
          page_idx: 0,
          par_idx: 11,
          line_idx: 12,
          x_left: 0.0682,
          y_top: 0.4079,
          x_right: 0.1812,
          y_bottom: 0.417,
          display_text: "1.2 Initial Prices",
        },
      ],
    },
    {
      level: 0,
      lines: [
        {
          page_idx: 0,
          par_idx: 13,
          line_idx: 14,
          x_left: 0.0682,
          y_top: 0.4885,
          x_right: 0.2067,
          y_bottom: 0.4997,
          display_text: "1.3 Pricing Changes",
        },
      ],
    },
    {
      level: 0,
      lines: [
        {
          page_idx: 0,
          par_idx: 15,
          line_idx: 20,
          x_left: 0.0682,
          y_top: 0.627,
          x_right: 0.3165,
          y_bottom: 0.636,
          display_text: "1.4 PURCHASE COMMITMENTS",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 0,
          par_idx: 16,
          line_idx: 21,
          x_left: 0.0682,
          y_top: 0.6739,
          x_right: 0.1969,
          y_bottom: 0.683,
          display_text: "1.4.1 Commitment",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 0,
          par_idx: 18,
          line_idx: 26,
          x_left: 0.0682,
          y_top: 0.7982,
          x_right: 0.2471,
          y_bottom: 0.8073,
          display_text: "1.4.2 Commitment Deficit",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 1,
          par_idx: 8,
          line_idx: 8,
          x_left: 0.0682,
          y_top: 0.2754,
          x_right: 0.2545,
          y_bottom: 0.287,
          display_text: "1.4.3 Changes to Customer",
        },
      ],
    },
    {
      level: 0,
      lines: [
        {
          page_idx: 1,
          par_idx: 10,
          line_idx: 10,
          x_left: 0.0678,
          y_top: 0.3557,
          x_right: 0.358,
          y_bottom: 0.3648,
          display_text: "2. PAYMENT AND DELIVERY TERMS",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 1,
          par_idx: 11,
          line_idx: 11,
          x_left: 0.0678,
          y_top: 0.403,
          x_right: 0.2028,
          y_bottom: 0.4145,
          display_text: "2.1 Payment Terms",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 1,
          par_idx: 13,
          line_idx: 15,
          x_left: 0.0678,
          y_top: 0.5128,
          x_right: 0.2333,
          y_bottom: 0.5239,
          display_text: "2.2 Shipment Stoppages",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 1,
          par_idx: 15,
          line_idx: 19,
          x_left: 0.0678,
          y_top: 0.6221,
          x_right: 0.1325,
          y_bottom: 0.6312,
          display_text: "2.3 Taxes",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 1,
          par_idx: 17,
          line_idx: 23,
          x_left: 0.0678,
          y_top: 0.7318,
          x_right: 0.1514,
          y_bottom: 0.7433,
          display_text: "2.4 Delivery",
        },
      ],
    },
    {
      level: 0,
      lines: [
        {
          page_idx: 2,
          par_idx: 7,
          line_idx: 7,
          x_left: 0.0675,
          y_top: 0.2482,
          x_right: 0.1325,
          y_bottom: 0.2569,
          display_text: "3. TERM\r",
        },
      ],
    },
    {
      level: 0,
      lines: [
        {
          page_idx: 2,
          par_idx: 9,
          line_idx: 14,
          x_left: 0.0678,
          y_top: 0.434,
          x_right: 0.1815,
          y_bottom: 0.4431,
          display_text: "4. WARRANTY\r",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 2,
          par_idx: 11,
          line_idx: 20,
          x_left: 0.0678,
          y_top: 0.6052,
          x_right: 0.2592,
          y_bottom: 0.6167,
          display_text: "4.2 Consumables Warranty",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 2,
          par_idx: 13,
          line_idx: 27,
          x_left: 0.0678,
          y_top: 0.7582,
          x_right: 0.2145,
          y_bottom: 0.7697,
          display_text: "4.3 Warranty Claims",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 3,
          par_idx: 7,
          line_idx: 7,
          x_left: 0.0678,
          y_top: 0.2482,
          x_right: 0.2479,
          y_bottom: 0.2597,
          display_text: "4.4 Limitation of Liability",
        },
      ],
    },
    {
      level: 0,
      lines: [
        {
          page_idx: 3,
          par_idx: 9,
          line_idx: 14,
          x_left: 0.0678,
          y_top: 0.4012,
          x_right: 0.1706,
          y_bottom: 0.4103,
          display_text: "5. DEFAULTS",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 3,
          par_idx: 10,
          line_idx: 15,
          x_left: 0.0678,
          y_top: 0.4485,
          x_right: 0.2565,
          y_bottom: 0.4576,
          display_text: "5.1 Notice and Cure Period",
        },
      ],
    },
    {
      level: 0,
      lines: [
        {
          page_idx: 3,
          par_idx: 12,
          line_idx: 19,
          x_left: 0.0678,
          y_top: 0.5579,
          x_right: 0.2792,
          y_bottom: 0.567,
          display_text: "6. SECURITY AGREEMENT\r",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 3,
          par_idx: 14,
          line_idx: 25,
          x_left: 0.0678,
          y_top: 0.7764,
          x_right: 0.1976,
          y_bottom: 0.7879,
          display_text: "7.1 Confidentiality",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 4,
          par_idx: 7,
          line_idx: 7,
          x_left: 0.0678,
          y_top: 0.2482,
          x_right: 0.3185,
          y_bottom: 0.2597,
          display_text: "7.2 Intellectual Property Ownership",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 4,
          par_idx: 9,
          line_idx: 11,
          x_left: 0.0678,
          y_top: 0.3579,
          x_right: 0.2373,
          y_bottom: 0.3694,
          display_text: "7.3 Obligation to Report",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 4,
          par_idx: 11,
          line_idx: 14,
          x_left: 0.0678,
          y_top: 0.4527,
          x_right: 0.1616,
          y_bottom: 0.4618,
          display_text: "7.4 No Resale",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 4,
          par_idx: 13,
          line_idx: 17,
          x_left: 0.0678,
          y_top: 0.5479,
          x_right: 0.2176,
          y_bottom: 0.5594,
          display_text: "7.5 Entire Agreement",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 4,
          par_idx: 15,
          line_idx: 23,
          x_left: 0.0678,
          y_top: 0.6867,
          x_right: 0.1839,
          y_bottom: 0.6958,
          display_text: "7.6 Amendments",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 4,
          par_idx: 17,
          line_idx: 26,
          x_left: 0.0678,
          y_top: 0.7815,
          x_right: 0.1678,
          y_bottom: 0.7906,
          display_text: "7.7 No Waiver",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 5,
          par_idx: 7,
          line_idx: 7,
          x_left: 0.0678,
          y_top: 0.2482,
          x_right: 0.1588,
          y_bottom: 0.2573,
          display_text: "7.8 Remedies",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 5,
          par_idx: 9,
          line_idx: 11,
          x_left: 0.0678,
          y_top: 0.3579,
          x_right: 0.1843,
          y_bottom: 0.3694,
          display_text: "7.9 No Invalidity",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 5,
          par_idx: 11,
          line_idx: 14,
          x_left: 0.0678,
          y_top: 0.4527,
          x_right: 0.2612,
          y_bottom: 0.4643,
          display_text: "7.10 Successors and Assigns",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 5,
          par_idx: 13,
          line_idx: 16,
          x_left: 0.0678,
          y_top: 0.5333,
          x_right: 0.2533,
          y_bottom: 0.5448,
          display_text: "7.11 Compliance with Law",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 5,
          par_idx: 15,
          line_idx: 21,
          x_left: 0.0678,
          y_top: 0.6576,
          x_right: 0.1663,
          y_bottom: 0.6667,
          display_text: "7.12 Medicare",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 5,
          par_idx: 17,
          line_idx: 28,
          x_left: 0.0678,
          y_top: 0.8106,
          x_right: 0.2098,
          y_bottom: 0.8221,
          display_text: "7.13 Governing Law",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 6,
          par_idx: 8,
          line_idx: 8,
          x_left: 0.0678,
          y_top: 0.2754,
          x_right: 0.2549,
          y_bottom: 0.287,
          display_text: "7.14 Force Majeure Events",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 6,
          par_idx: 10,
          line_idx: 13,
          x_left: 0.0678,
          y_top: 0.3994,
          x_right: 0.1596,
          y_bottom: 0.4085,
          display_text: "7.15 Survival",
        },
      ],
    },
    {
      level: 1,
      lines: [
        {
          page_idx: 6,
          par_idx: 12,
          line_idx: 18,
          x_left: 0.0678,
          y_top: 0.5236,
          x_right: 0.1447,
          y_bottom: 0.5327,
          display_text: "7.16 Notice",
        },
      ],
    },
  ],
};
