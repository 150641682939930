import React, {
  forwardRef,
  useRef,
  useState,
  useEffect,
  ReactElement,
} from "react";
import classNames from "classnames";

import Box from "../Box";
import usePreviousValue from "@/_legacy/hooks/usePreviousValue";
import { useMergeRefs } from "@/hooks";
import controlEnterClasses from "./controlEnterClasses";
import controlLeaveClasses from "./controlLeaveClasses";

import "./Transition.styles.scss";

/**
 * Component encapsulating CSS mount/unmount transitions.
 *
 * Inspired by and adpated from:
 * https://sebastiandedeyne.com/javascript-framework-diet/enter-leave-transitions/
 * https://headlessui.dev/react/transition
 */
const Transition = forwardRef<HTMLDivElement, TransitionProps>(
  (
    {
      children,
      show,
      variant,
      className = "",
      onEntered = () => {},
      onLeft = () => {},
      ...props
    },
    ref
  ) => {
    const elementRef = useRef(null);
    const [isVisible, setIsVisible] = useState(show);
    const previousIsVisible = usePreviousValue(isVisible);

    const isVisibleChanged = isVisible !== previousIsVisible;

    useEffect(() => {
      if (show) {
        setIsVisible(true);
      } else {
        controlLeaveClasses(elementRef.current).then(() => setIsVisible(false));
      }
    }, [show]);

    useEffect(() => {
      if (isVisibleChanged) {
        if (isVisible) {
          controlEnterClasses(elementRef.current).then(onEntered);
        } else {
          onLeft();
        }
      }
    }, [isVisibleChanged, isVisible, onEntered, onLeft]);

    const boxRef = useMergeRefs([elementRef, ref]);

    return isVisible ? (
      <Box
        className={classNames(
          "Transition",
          `Transition--${variant}`,
          className
        )}
        ref={boxRef}
        {...props}
      >
        {children}
      </Box>
    ) : null;
  }
);

Transition.displayName = "Transition";

interface TransitionProps {
  /**
   * Any children elements to render.
   */
  children: ReactElement;
  /**
   * Whether the component and its children should be visible/in the DOM.
   */
  show: boolean;
  /**
   * Animation variant.
   */
  variant: "FadeIn" | "FadeInUp" | "Modal";
  /**
   * HTML class.
   */
  className?: string;
  /**
   * Callback firing after enter animation finishes.
   */
  onEntered?: () => void;
  /**
   * Callback firing after leave animation finishes.
   */
  onLeft?: () => void;
}

export default Transition;
