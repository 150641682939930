// eslint-disable-next-line no-unused-vars
import React, { FC, useEffect } from "react";
import { toast } from "react-toastify";

import usePreviousValue from "@/_legacy/hooks/usePreviousValue";
import { useCurrentWorkspace } from "@/data/workspaces/queries";

/**
 * Send a notification if we detect an increase in page quota
 */
const PageQuotaObserver: FC = () => {
  const workspaceQuery = useCurrentWorkspace();

  const workspace = workspaceQuery.data;

  const hasDigiDocsAccess = workspace?.hasDigiDocsAccess;

  const workspaceId = workspace?.id;
  const previousWorkspaceId = usePreviousValue(workspaceId);

  const currentPageQuota = workspace?.pageQuota;
  const previousPageQuota = usePreviousValue(workspace?.pageQuota);

  const currentOcrQuota = workspace?.ocrQuota;
  const previousOcrQuota = usePreviousValue(workspace?.ocrQuota);

  useEffect(() => {
    if (workspaceId !== previousWorkspaceId) return;

    if (hasDigiDocsAccess && previousPageQuota < currentPageQuota) {
      toast.success(
        `You've just received a ${
          currentPageQuota - previousPageQuota
        } page boost! 🙌`
      );
    }
  }, [
    hasDigiDocsAccess,
    currentPageQuota,
    previousPageQuota,
    workspaceId,
    previousWorkspaceId,
  ]);

  useEffect(() => {
    if (workspaceId !== previousWorkspaceId) return;

    if (previousOcrQuota < currentOcrQuota) {
      toast.success(
        `You've just received a ${currentOcrQuota - previousOcrQuota} ${
          hasDigiDocsAccess ? "OCR " : ""
        }page boost! 🙌`
      );
    }
  }, [
    hasDigiDocsAccess,
    currentOcrQuota,
    previousOcrQuota,
    workspaceId,
    previousWorkspaceId,
  ]);
  return null;
};

export default PageQuotaObserver;
