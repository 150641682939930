import nextFrame from "./nextFrame";
import afterTransition from "./afterTransition";

/**
 * Control HTML classes for different phases of a leave transition.
 *
 * @param element - Element for which to control classes.
 *
 * @returns - Promise resolving after all class changes have been applied.
 *
 * @example
 * controlLeaveClasses(elementRef.current)
 *   .then(() => console.log("Finished applying classes!"));
 */
const controlLeaveClasses = async (element: HTMLElement) => {
  element?.classList.add("Transition--Leave");
  element?.classList.add("Transition--Leave--From");

  await nextFrame();

  element?.classList.remove("Transition--Leave--From");
  element?.classList.add("Transition--Leave--To");

  await afterTransition(element);

  element?.classList.remove("Transition--Leave--To");
  element?.classList.remove("Transition--Leave");
};

export default controlLeaveClasses;
