import React, { FC } from "react";
import Link from "next/link";

import { Box, Button, Text } from "@/components";
import { LateralLinks } from "@/constants";

import "./CookieConsentBanner.styles.scss";

const CookieConsentBanner: FC<CookieConsentBannerProps> = ({
  onAllowAllClick,
  onShowOptionsClick,
}) => {
  return (
    <Box className="CookieConsentBanner">
      <Box className="CookieConsentBanner__Container">
        <Text as="div">
          By clicking <strong>“Agree”</strong>, you agree to the storing of
          cookies on your device to enhance site navigation, analyse site usage,
          and assist in our marketing efforts. View our{" "}
          <Link href={LateralLinks.PRIVACY}>Privacy Policy</Link> for more
          information.
        </Text>
        <Box className="CookieConsentBanner__ButtonWrapper">
          <Text role="button" variant="TextLink" onClick={onShowOptionsClick}>
            More options
          </Text>

          <Button onClick={onAllowAllClick}>Agree</Button>
        </Box>
      </Box>
    </Box>
  );
};

export type CookieConsentBannerProps = {
  onAllowAllClick: () => void;
  onShowOptionsClick: () => void;
};

export default CookieConsentBanner;
