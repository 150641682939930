import "@/styles/globals.scss";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "react-toastify/dist/ReactToastify.css";

// fix big flashing icons in prod build
// https://github.com/FortAwesome/react-fontawesome/issues/234#issue-437932016
import { config } from "@fortawesome/fontawesome-svg-core";
import { NextPage } from "next";
import PlausibleProvider from "next-plausible";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import React, { useEffect, useState } from "react";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import { IntercomProvider } from "react-use-intercom";

import { NavSidebarProvider as _LegacyNavSidebarProvider } from "@/_legacy/context/NavSidebar";
import { AuthGuard, NewTooltip, PageQuotaObserver } from "@/components";
import CookieConsent from "@/components/CookieConsent";
import { INTERCOM_APP_ID } from "@/constants";
import { ModalsProvider } from "@/contexts/Modals";
import { useIsPublicPath } from "@/hooks";
import queryClient from "@/singletons/queryClient";
import { initAmplitude } from "@/utils/analytics";

config.autoAddCss = false;

config.autoAddCss = false;

if (process.env.NEXT_PUBLIC_API_MOCKING === "enabled") {
  require("../mocks");
}

const meta = {
  title: "Lateral App",
  description: "Supercharge your literature review with Lateral AI",
  // full absolute path required for twitter
  image: "https://app.lateral.io/images/site-preview.jpg",
  author: "Lateral",
  type: "website",
  favicon: "/images/favicon.png",
  twitter: {
    card: "summary_large_image",
    handle: "@uselateral",
  },
};

/**
 * @param root0
 * @param root0.Component
 * @param root0.pageProps
 * @param root0.err
 */
function MyApp({ Component, pageProps, err }) {
  const isPublicPath = useIsPublicPath();
  const [isClientSide, setIsClientSide] = useState(false);
  const router = useRouter();

  useEffect(() => {
    initAmplitude();
    setIsClientSide(true);
  }, [setIsClientSide]);

  useEffect(() => {
    if (router.asPath.includes("#id=")) {
      router.replace(router.asPath.split("#")[0]);
    }
  }, [router.asPath]);

  // https://nextjs.org/docs/basic-features/layouts#per-page-layouts
  const getLayout = Component?.getLayout ?? ((page: NextPage) => page);

  return (
    <>
      <Head>
        <link rel="shortcut icon" href={meta.favicon} />
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta property="og:title" content={meta.title} />
        <meta property="og:description" content={meta.description} />
        <meta property="og:type" content={meta.type} />
        <meta property="og:image" content={meta.image} />
        <meta name="twitter:card" content={meta.twitter.card} />
        <meta name="twitter:creator" content={meta.twitter.handle} />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <meta name="twitter:image" content={meta.image} />
      </Head>
      <Script src="/scripts/canny.js" />
      <Script src="/scripts/usetiful.js" />
      <Script src="https://js.stripe.com/v3/" />
      {/* <ToastContainer icon={false} theme="dark" /> */}
      <ToastContainer />
      <QueryClientProvider client={queryClient}>
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={!isPublicPath}>
          <PlausibleProvider
            domain="app.lateral.io"
            customDomain="https://plausible.lateral.io"
            selfHosted={true}
          >
            {/* attempts to use local storage, so only render on client */}
            {isClientSide && isPublicPath && <CookieConsent />}
            <AuthGuard>
              <ReactQueryDevtools
                initialIsOpen={false}
                position="bottom-right"
              />
              <PageQuotaObserver />
              <NewTooltip.Provider>
                <_LegacyNavSidebarProvider>
                  <ModalsProvider>
                    {getLayout(<Component {...pageProps} err={err} />)}
                  </ModalsProvider>
                </_LegacyNavSidebarProvider>
              </NewTooltip.Provider>
            </AuthGuard>
          </PlausibleProvider>
        </IntercomProvider>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
