import { useRouter } from "next/router";

/**
 * Get the id of the current workspace from the current URL.
 */
const useWorkspaceIdParam = () => {
  const router = useRouter();

  return router.query?.workspaceId
    ? parseInt(router.query.workspaceId as string, 10)
    : null;
};

export default useWorkspaceIdParam;
