import "./CookieConsent.styles.scss";

import Cookies from "js-cookie";
import React, { FC, useEffect } from "react";

import { Box } from "@/components";
import { useCookieConsent } from "@/hooks";
import { Category, Status, View } from "@/hooks/useCookieConsent";

import CookieConsentBanner from "./CookieConsentBanner";
import CookieConsentFloatingButton from "./CookieConsentFloatingButton";
import CookieConsentPreferences from "./CookieConsentPreferences";

const EssentialCookies = {
  CSRF_TOKEN: "csrftoken",
  SESSION_ID: "sessionid",
  GOOGLE_OPT_OUT: "optOutGoogleTracking",
};

const CookieConsent: FC = () => {
  const cookieConsent = useCookieConsent();

  useEffect(() => {
    const sendAnalyticsEvent = () => {};

    const deleteCookies = () => {
      const cookies = Cookies.get();

      Object.keys(cookies).forEach(key => {
        if (!Object.values(EssentialCookies).includes(key)) {
          Cookies.remove(key);
        }
      });

      Cookies.set(EssentialCookies.GOOGLE_OPT_OUT, true);
    };

    const allowAll = () => {
      sendAnalyticsEvent();
    };

    const rejectAll = () => {
      deleteCookies();
    };

    switch (cookieConsent.state.status) {
      case Status.ALLOWED_ALL:
        allowAll();
        break;
      case Status.REJECTED_ALL:
        rejectAll();
        break;
      case Status.ALLOWED_SOME:
        /* eslint-disable no-case-declarations */
        const allCategoriesAreAllowed =
          cookieConsent.state.categories.length ===
          Object.keys(Category).length;
        const noCategoriesAreAllowed =
          cookieConsent.state.categories.length === 0;
        /* eslint-enable no-case-declarations */

        if (allCategoriesAreAllowed) {
          allowAll();
        } else if (noCategoriesAreAllowed) {
          rejectAll();
        } else if (
          cookieConsent.state.categories.includes(Category.ANALYTICS)
        ) {
          sendAnalyticsEvent();
        }
        break;
      case Status.UNDECIDED:
      default:
        break;
    }
  }, [cookieConsent.state.status, cookieConsent.state.categories]);

  const initialAllowedCategories =
    cookieConsent.state.status === Status.ALLOWED_ALL
      ? (Object.values(Category) as Array<Category>)
      : cookieConsent.state.categories;

  return (
    <Box className="CookieConsent">
      {cookieConsent.state.view === View.BANNER && (
        <CookieConsentBanner
          onAllowAllClick={cookieConsent.handleAllowAllClick}
          onShowOptionsClick={cookieConsent.handleShowOptionsClick}
        />
      )}

      {cookieConsent.state.view === View.FBA && (
        <CookieConsentFloatingButton
          onClick={cookieConsent.handleShowOptionsClick}
        />
      )}

      <CookieConsentPreferences
        // this key forces to re-render this component when the initial
        // allowed categories change, to re-set the component's internal toggle
        // states for the categories. This is easier than dealing
        // with the referential equality problem of this array inside of
        // the component in a useEffect dependency array for example.
        key={JSON.stringify(initialAllowedCategories)}
        show={cookieConsent.state.view === View.PREFERENCES}
        initialAllowedCategories={initialAllowedCategories}
        onCloseClick={cookieConsent.handleHideOptionsClick}
        onAllowAllClick={cookieConsent.handleAllowAllClick}
        onRejectAllClick={cookieConsent.handleRejectAllClick}
        onAllowSomeClick={cookieConsent.handleAllowSomeClick}
      />
    </Box>
  );
};

export default CookieConsent;
