import React, { FC } from "react";

import Box from "../Box";

import "./CookieConsentFloatingButton.styles.scss";

const CookieConsentFloatingButton: FC<CookieConsentFloatingButtonProps> = ({
  onClick,
}) => {
  return (
    <Box
      role="button"
      className="CookieConsentFloatingButton"
      onClick={onClick}
    >
      <img src="/images/cookie.svg" />
    </Box>
  );
};

export type CookieConsentFloatingButtonProps = {
  onClick: () => void;
};

export default CookieConsentFloatingButton;
