import sortBy from "lodash/sortBy";
import { useQuery, useQueryClient, UseQueryOptions } from "react-query";

import { HIGH_LIST_PAGE_SIZE } from "@/constants";
import { Id, ListPage } from "@/data/types";
import api from "@/data/workspaces/api";
import keys from "@/data/workspaces/keys";
import { Workspace } from "@/data/workspaces/types";
import { useWorkspaceIdParam } from "@/hooks";

/**
 * Hook for fetching list page of workspaces.
 *
 * @param options - Query hook options.
 */
export const useWorkspaces = (
  options: UseQueryOptions<ListPage<Workspace>> = {}
) => {
  const params = { page: 0, pageSize: HIGH_LIST_PAGE_SIZE };
  const queryClient = useQueryClient();

  return useQuery<ListPage<Workspace>>(
    keys.list(params),
    () => api.fetchListPage(params),
    {
      // do not fetch on mount on default, instead override this
      // in `AuthGuard` to only fetch when user visits protected path.
      // All other uses of the hook will use cache data then without fetching.
      enabled: false,
      retry: false,
      onSettled: data => {
        data?.results?.forEach(workspace => {
          queryClient.setQueryData<Workspace>(
            keys.detail(workspace.id),
            workspace
          );
        });
      },
      select: data => ({
        ...data,
        results: sortBy(data?.results ?? [], workspace => workspace.id),
      }),
      ...options,
    }
  );
};

/**
 * Hook for fetching single workspace.
 *
 * @param id - Id of the workspace to fetch.
 * @param options - Query hook options.
 */
export const useWorkspace = (
  id: Id,
  options: UseQueryOptions<Workspace> = {}
) => useQuery<Workspace>(keys.detail(id), () => api.fetchById(id), options);

/**
 * Hook for fetching current workspace based on id from url.
 *
 * @param options - Query hook options.
 */
export const useCurrentWorkspace = (
  options: UseQueryOptions<Workspace> = {}
) => {
  const id = useWorkspaceIdParam();

  return useQuery<Workspace>(keys.detail(id), () => api.fetchById(id), {
    enabled: !!id,
    staleTime: 5000,
    retry: false,
    ...options,
  });
};
