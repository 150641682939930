import React, { FC, useState } from "react";

import { Box, Button, Text } from "@/components";
import Modal from "@/_legacy/components/Modal";
import Toggle from "@/_legacy/components/Toggle";
import { Category } from "@/hooks/useCookieConsent";

import "./CookieConsentPreferences.styles.scss";

const CATEGORIES = [
  // {
  //   id: Category.MARKETING,
  //   title: "Marketing",
  //   description:
  //     "These items are used to deliver advertising that is more relevant to you and your interests. They may also be used to limit the number of times you see an advertisement and measure the effectiveness of advertising campaigns. Advertising networks usually place them with the website operator’s permission.",
  // },
  // {
  //   id: Category.PERSONALIZATION,
  //   title: "Personalization",
  //   description:
  //     "These items allow the website to remember choices you make (such as your user name, language, or the region you are in) and provide enhanced, more personal features. For example, a website may provide you with local weather reports or traffic news by storing data about your current location.",
  // },
  {
    id: Category.ANALYTICS,
    title: "Analytics",
    description:
      "These items help the website operator understand how its website performs, how visitors interact with the site, and whether there may be technical issues. This storage type usually doesn’t collect information that identifies a visitor.",
  },
];

const CookieConsentPreferences: FC<CookieConsentPreferencesProps> = ({
  show,
  initialAllowedCategories,
  onCloseClick,
  onAllowAllClick,
  onRejectAllClick,
  onAllowSomeClick,
}) => {
  const [allowedCategories, setAllowedCategories] = useState(
    initialAllowedCategories
  );

  return (
    <Modal
      show={show}
      onCloseClick={onCloseClick}
      onClosed={() => setAllowedCategories(initialAllowedCategories)}
      className="CookieConsentPreferences"
    >
      <Text variant="Heading2" as="h2">
        Privacy Preference Center
      </Text>
      <Text variant="Body" as="p">
        When you visit websites, they may store or retrieve data in your
        browser. This storage is often necessary for the basic functionality of
        the website. The storage may be used for marketing, analytics, and
        personalization of the site, such as storing your preferences. Privacy
        is important to us, so you have the option of disabling certain types of
        storage that may not be necessary for the basic functioning of the
        website. Blocking categories may impact your experience on the website.
      </Text>
      <Box className="CookieConsentPreferences__AcceptReject">
        <Button onClick={onRejectAllClick} variant="Secondary">
          Reject all cookies
        </Button>
        <Button onClick={onAllowAllClick}>Allow all cookies</Button>
      </Box>
      <Text variant="Heading2" as="h2">
        Manage Consent Preferences by Category
      </Text>

      <Box className="CookieConsentPreferences__Preference">
        <Box className="CookieConsentPreferences__Preference__Toggle">
          <Text variant="Heading3" as="h3">
            Essential
          </Text>
          <Text
            className="CookieConsentPreferences__Preference__Toggle__AlwaysActive"
            variant="Body2"
          >
            Always Active
          </Text>
        </Box>
        <Text variant="Body2" as="h1">
          These items are required to enable basic website functionality.
        </Text>
      </Box>

      {CATEGORIES.map(category => (
        <Box key={category.id} className="CookieConsentPreferences__Preference">
          <Box className="CookieConsentPreferences__Preference__Toggle">
            <Text variant="Heading3" as="h3">
              {category.title}
            </Text>
            <Toggle
              checked={allowedCategories.includes(category.id)}
              onChange={() => {
                if (allowedCategories.includes(category.id)) {
                  setAllowedCategories(
                    allowedCategories.filter(id => id !== category.id)
                  );
                } else {
                  setAllowedCategories([...allowedCategories, category.id]);
                }
              }}
            />
          </Box>
          <Text variant="Body2" as="h1">
            {category.description}
          </Text>
        </Box>
      ))}

      <Button
        variant="Secondary"
        className="CookieConsentPreferences__Confirm"
        onClick={() => onAllowSomeClick(allowedCategories)}
      >
        Confirm my preferences and close
      </Button>
    </Modal>
  );
};

/* eslint-disable no-unused-vars */
export type CookieConsentPreferencesProps = {
  show: boolean;
  initialAllowedCategories: Array<Category>;
  onCloseClick: () => void;
  onAllowAllClick: () => void;
  onRejectAllClick: () => void;
  onAllowSomeClick: (allowedCategories: Array<Category>) => void;
};
/* eslint-enable no-unused-vars */

export default CookieConsentPreferences;
