/* eslint-disable react/display-name */

import React, { forwardRef } from "react";
import classNames from "classnames";
import type * as Polymorphic from "@radix-ui/react-polymorphic";

import "./Button.styles.scss";

/**
 * Flexible button component.
 *
 * `variant` prop corresponds to Lateral design system variants.
 *
 * @example
 * <Button onClick={handleClick}>Click</Button>
 *
 * @example
 * <Button as="a" href="/dashboard">Dashboard</Button>
 */
const Button = forwardRef(
  (
    {
      as: Component = "button",
      variant,
      colorScheme,
      className,
      onClick,
      ...props
    },
    forwardedRef
  ) => (
    <Component
      ref={forwardedRef}
      className={classNames(
        "ButtonNEW",
        `ButtonNEW--${variant}`,
        `ButtonNEW--Color-${colorScheme}`,
        className
      )}
      onClick={event => {
        onClick?.(event);
        // prevent focus outline to stay after clicking
        // @ts-ignore
        event?.target?.blur?.();
      }}
      {...props}
    />
  )
) as Polymorphic.ForwardRefComponent<"button", ButtonProps>;

Button.displayName = "Button";

Button.defaultProps = {
  variant: "Primary",
  colorScheme: "Default",
};

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Design system style variant to apply.
   */
  variant?: "Primary" | "Secondary" | "Tertiary" | "Tertiary-B";
  /**
   * Special color variants.
   */
  colorScheme?: "Default" | "Gradient";
}

export default Button;
