import { rest } from "msw";

import doc from "./fixtures/documents/469";
import outline from "./fixtures/documents/469/outline";

export const handlers = [
  rest.get("/api/api/documents/:id", (_req, res, ctx) =>
    res(ctx.status(200), ctx.json(doc))
  ),
  rest.get("/api/api/documents/:id/outline", (_req, res, ctx) =>
    res(ctx.status(200), ctx.json(outline))
  ),
];
