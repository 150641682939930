import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const NavSidebarContext = createContext({});

/**
 * Context provider to make toggling nav globally available.
 */
const NavSidebarProvider = ({ children }) => {
  const [open, setOpen] = useState(true);

  return (
    <NavSidebarContext.Provider value={[open, setOpen]}>
      {children}
    </NavSidebarContext.Provider>
  );
};

NavSidebarProvider.propTypes = {
  /**
   * Component children to render.
   */
  children: PropTypes.any,
};

const useNavSidebar = () => useContext(NavSidebarContext);

export { NavSidebarProvider, useNavSidebar };
