import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

import "./Loader.styles.scss";

const Loader = ({
  size = "2",
  message = null,
  icon = faSpinnerThird,
  children = null,
  type = "container",
  className = "",
  style = {},
}) => {
  let containerStyles = {};
  if (type === "container") {
    containerStyles = {
      height: "100%",
      width: "100%",
      textAlign: "center",
    };
  } else if (type === "fullScreen") {
    containerStyles = {
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#006bff",
    };
  } else if (type === "inline") {
    containerStyles = {
      display: "inline",
    };
  }

  return (
    <div
      data-testid="loader"
      className={"AppLoadingAnimation " + className}
      style={{ ...containerStyles, ...style }}
    >
      <FontAwesomeIcon
        icon={icon}
        spin
        size={`${size}x`}
        style={{ marginRight: type !== "inline" ? 20 : 0 }}
      />
      {message || children}
    </div>
  );
};

Loader.propTypes = {
  type: PropTypes.oneOf(["inline", "container", "fullScreen"]),
};

export default Loader;
