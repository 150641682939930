import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";
import React, { FC, useEffect } from "react";
import { useIntercom } from "react-use-intercom";

import Loader from "@/_legacy/components/Loader";
import { useMe } from "@/data/auth/queries";
import { useWorkspaces } from "@/data/workspaces/queries";
import { useIsPublicPath, useWorkspaceIdParam } from "@/hooks";
import { setAmplitudeUserId } from "@/utils/analytics";

// if I access /
// no auth, then render null, no redirect
// auth, and I have workspaces, render loader and redirect to my first workspace id
// auth, and I have no workspaces, render create workspace prompt, no redirect
// auth, while data still loading, render loader and no redirect
// auth, while router is not ready, render loader and no redirect

// if I access /70 or /70/*
// no auth, then render null, no redirect
// auth, and I have workspaces, and I have access to /70, render children, and no redirect
// auth, and I have workspaces and I have NO access to /70, render loader and redirect to my first workspace id
// auth, and I have no workspaces, render create workspace prompt, no redirect
// auth, while data still loading, render loader and no redirect
// auth, while router is not ready, render loader and no redirect

// if I access /login render children, no redirect and do not load data
// if I access /sign-up render children, no redirect and do not load data
// if I access /projects/public/a638c9ec-3a38-40ab-acbb-2f4615fcac77/ render children, no redirect and do not load data

/**
 * Decides if page should be rendered based on user auth state.
 */
const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const router = useRouter();
  const intercom = useIntercom();
  const isPublicPath = useIsPublicPath();
  const pathIsProtected = !isPublicPath;

  const workspacesQuery = useWorkspaces({
    enabled: pathIsProtected,
  });

  // Canny SSO is special as it needs to use the user but not check
  // any workspaces stuff, so let it use the user Query all the time
  const userQuery = useMe({
    enabled: pathIsProtected || router.pathname == "/canny-sso",
  });

  const workspaceIdFromUrl = useWorkspaceIdParam();
  const userHasAccessToWorkspaceIdFromUrl = Boolean(
    workspacesQuery.data?.results?.find(
      workspace => workspace.id === workspaceIdFromUrl
    ) ?? false
  );
  const firstUserWorkspaceId = workspacesQuery.data?.results?.[0]?.id;

  const user = userQuery.data;
  const id = user?.id;
  const email = user?.email;

  const isAllDataLoaded = workspacesQuery.isSuccess && userQuery.isSuccess;
  const userHasWorkspaces = workspacesQuery.data?.results?.length > 0;

  const shouldRedirectToWorkspaceDashboard =
    router.isReady &&
    (!workspaceIdFromUrl || !userHasAccessToWorkspaceIdFromUrl) &&
    userHasWorkspaces;
  const isRedirectingToWorkspaceDashboard = shouldRedirectToWorkspaceDashboard;

  const isRedirectingToCreateWorkspacePage =
    pathIsProtected &&
    isAllDataLoaded &&
    !userHasWorkspaces &&
    router.asPath !== "/";

  useEffect(() => {
    if (!pathIsProtected || !isAllDataLoaded) return;

    if (userHasWorkspaces) {
      if (shouldRedirectToWorkspaceDashboard) {
        router.push({
          pathname: "/[workspaceId]/",
          query: {
            workspaceId: firstUserWorkspaceId,
          },
        });
      }
    } else {
      if (router.asPath !== "/") router.push("/");
    }
  }, [
    pathIsProtected,
    isAllDataLoaded,
    userHasWorkspaces,
    firstUserWorkspaceId,
    router.push,
    shouldRedirectToWorkspaceDashboard,
  ]);

  useEffect(() => {
    if (id && email) {
      Sentry.setUser({
        id: id as unknown as string,
        email,
        ip_address: "{{auto}}",
      });
      // @ts-ignore
      window.usetifulTags = {
        userId: id as unknown as string,
        showTour: id > 5203 ? "yes" : "no",
      };
    }
  }, [id, email]);

  useEffect(() => {
    if (id && pathIsProtected) {
      setAmplitudeUserId(id);
      intercom.boot();
      intercom.update({
        userId: `${id}`,
        name: user?.firstName,
        email: user?.email,
        userHash: user?.intercomToken,
        customAttributes: {
          invite_code: user?.inviteCode,
          number_of_invited_users: user?.numberOfInvitedUsers,
        },
      });
    }
  }, [id, intercom.boot, intercom.update, pathIsProtected]);

  if (
    pathIsProtected &&
    (!isAllDataLoaded ||
      isRedirectingToWorkspaceDashboard ||
      isRedirectingToCreateWorkspacePage)
  ) {
    return <Loader type="fullScreen" />;
  }

  // do not render children to prevent requests from happening
  // that would require authentication. The 403 error that stemmed
  // from the previously made user fetch request is catched
  // in a global axios error handler, which redirects to the
  // login page instead
  if (userQuery.isError || workspacesQuery.isError) {
    return null;
  }

  return children;
};

export type AuthGuardProps = {
  /**
   * Children elements to render.
   */
  children: any;
};

export default AuthGuard;
