import * as Sentry from "@sentry/nextjs";
import amplitude from "amplitude-js";
import { useCallback } from "react";
import { useIntercom } from "react-use-intercom";

import { isWindowAvailable } from "@/utils";

import { AnalyticsEvent, EventWithOptionalFields } from "./types";

const isClient = isWindowAvailable();
const apiKey =
  isClient && window.location.host === "app.lateral.io"
    ? process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY
    : process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY_DEV;

// eslint-disable-next-line no-unused-vars
const runSafely = (fn: (...args: any[]) => void, args: any[]) => {
  if (isClient && apiKey) {
    try {
      fn(...args);
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  } else if (isClient && !apiKey) {
    console.log("AMPLITUDE DEBUG:", args);
  }
};

export const initAmplitude = () => {
  runSafely(
    (...args) => {
      amplitude.getInstance().init(...args);
      amplitude.setVersionName(process.env.NEXT_PUBLIC_SENTRY_RELEASE || "dev");
    },
    [
      apiKey,
      null,
      {
        apiEndpoint:
          window.location.protocol == "http:"
            ? null
            : window.location.host + "/api/events/",
        includeReferrer: true,
        includeUtm: true,
        disableCookies: true,
        trackingOptions: {
          city: false,
          country: false,
          carrier: false,
          dma: false,
          ip_address: false,
          region: false,
        },
      },
    ]
  );
};

export const setAmplitudeUserDevice = (installationToken: string) => {
  runSafely(
    (...args) => {
      amplitude.getInstance().setDeviceId(...args);
    },
    [installationToken]
  );
};

export const setAmplitudeUserId = (userId: number) => {
  runSafely(
    forwardedId => {
      const paddedId = `${forwardedId}`.padStart(10, "0");
      amplitude.getInstance().setUserId(paddedId);
    },
    [userId]
  );
};

export const setAmplitudeUserProperties = (properties: {
  [key: string]: any;
}) => {
  runSafely(
    (...args) => {
      amplitude.getInstance().setUserProperties(...args);
    },
    [properties]
  );
};

export const sendAmplitudeData = (eventType: string, eventProperties = {}) => {
  runSafely(
    (...args) => {
      amplitude.getInstance().logEvent(...args);
    },
    [eventType, eventProperties]
  );
};

export const useAnalytics = () => {
  const intercom = useIntercom();

  const trackEvent = useCallback(
    (event: AnalyticsEvent) => {
      sendAmplitudeData(
        event.type,
        (event as EventWithOptionalFields)?.fields ?? {}
      );

      if (window.location.host === "app.lateral.io") {
        intercom?.trackEvent(
          event.type,
          (event as EventWithOptionalFields)?.fields ?? {}
        );
      }
    },
    [intercom?.trackEvent]
  );

  return {
    trackEvent,
  };
};

export default {
  useAnalytics,
};
