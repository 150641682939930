import { useQuery, UseQueryOptions } from "react-query";

import api from "@/data/auth/api";
import keys from "@/data/auth/keys";
import { Me } from "@/data/auth/types";

/**
 * Fetch the currently authenticated user.
 *
 * @returns - react-query useQuery hook call.
 */
export const useMe = (options: UseQueryOptions<Me> = {}) =>
  useQuery<Me>(keys.current(), () => api.fetchCurrentUser(), {
    // do not fetch on mount on default, instead override this
    // in `AuthGuard` to only fetch when user visits protected path.
    // All other uses of the hook will use cache data then without fetching.
    enabled: false,
    retry: false,
    ...options,
  });

/**
 * Get computed referral link from authenticated users' invite code.
 */
export const useReferralUrl = () => {
  const { data: user, ...rest } = useMe();

  return {
    ...rest,
    data: user
      ? `${window.location.origin}/sign-up/${user?.inviteCode}/`
      : undefined,
  };
};
