import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

import Transition from "@/components/Transition";

import "./Modal.styles.scss";

/**
 * General purpose modal with background overlay.
 */
const Modal = ({
  children,
  show,
  onCloseClick,
  onClosed = () => {},
  showCloseButton = true,
  className = "",
}) => {
  useEffect(() => {
    // close modal on pressing Escape key
    const handleKeyDown = event => {
      if (show && event.key === "Escape") {
        onCloseClick(event);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [onCloseClick]);

  return ReactDOM.createPortal(
    <Transition variant="Modal" show={show} onLeft={onClosed}>
      <div className={`Modal ${className}`.trim()} onClick={onCloseClick}>
        <div
          className="Modal__Window SlideUpTarget"
          onClick={event => event.stopPropagation()}
        >
          {showCloseButton && (
            <div className="Modal__Window__CloseIcon" onClick={onCloseClick}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          )}
          {children}
        </div>
      </div>
    </Transition>,
    document.getElementById("__next")
  );
};

Modal.propTypes = {
  /**
   * The content of the modal.
   */
  children: PropTypes.any.isRequired,
  /**
   * Whether the modal should be open.
   */
  show: PropTypes.bool.isRequired,
  /**
   * Function to handle an action to close the modal.
   */
  onCloseClick: PropTypes.func.isRequired,
  /**
   * Callback firing after modal close animation finishes.
   */
  onClosed: PropTypes.func,
  /**
   * HTML class.
   */
  className: PropTypes.string,
  /**
   * Whether to show the close icon button.
   */
  showCloseButton: PropTypes.bool,
};

export default Modal;
