import { MutableRefObject, LegacyRef, RefCallback, useCallback } from "react";

/**
 * Hook for merging multiple refs into one.
 *
 * @param refs - List of refs.
 *
 * @returns - Memoized function that acts as a ref
 * function and sets element reference to all passed refs.
 */
function useMergeRefs<T = any>(
  refs: Array<MutableRefObject<T> | LegacyRef<T>>
): RefCallback<T> {
  return useCallback(value => {
    refs.forEach(ref => {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref != null) {
        (ref as MutableRefObject<T | null>).current = value;
      }
    });
  }, []);
}

export default useMergeRefs;
