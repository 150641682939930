import React from "react";
import PropTypes from "prop-types";
import "./Toggle.styles.scss";

/**
 * Controlled toggle checkbox input.
 */
const Toggle = ({
  children,
  childrenPosition = "before",
  checked = false,
  onChange,
  onClick = () => {},
  className = "",
}) => {
  return (
    <label onClick={onClick} className={className}>
      {childrenPosition === "before" && (
        <span className="label-text before">{children}</span>
      )}
      <span className="toggle">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className="slider round"></span>
      </span>
      {childrenPosition === "after" && (
        <span className="label-text after">{children}</span>
      )}
    </label>
  );
};

Toggle.propTypes = {
  /**
   * Children to render as label.
   */
  children: PropTypes.any,
  /**
   * Position of the children label.
   */
  childrenPosition: PropTypes.oneOf(["before", "after"]),
  /**
   * Whether input is checked.
   */
  checked: PropTypes.bool,
  /**
   * Change handler.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Optional click callback handler.
   */
  onClick: PropTypes.func,
  /**
   * HTML class.
   */
  className: PropTypes.string,
};

export default Toggle;
