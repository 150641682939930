import React, { FC, useReducer, useCallback } from "react";

import { ActionType, ModalId, ModalState } from "./types";
import ModalsContext from "./ModalsContext";
import modalsReducer from "./modalsReducer";

const InitialState = Object.fromEntries(
  Object.keys(ModalId).map(modalId => [modalId, ModalState.CLOSED])
);

/**
 * Provider component for modals state that provides children
 * with functions for controlling global modal states.
 */
const ModalsProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(modalsReducer, InitialState);

  const openModal = useCallback(
    (modalId: ModalId) => dispatch({ type: ActionType.OPEN_MODAL, modalId }),
    [dispatch]
  );

  const closeModal = useCallback(
    (modalId: ModalId) => dispatch({ type: ActionType.CLOSE_MODAL, modalId }),
    [dispatch]
  );

  // add boolean check properties for convienience
  const modalStates = Object.fromEntries(
    Object.entries(state).map(([modalId, modalState]) => [
      modalId,
      {
        state: modalState,
        isOpen: modalState === ModalState.OPEN,
        isClosed: modalState === ModalState.CLOSED,
      },
    ])
  );

  return (
    <ModalsContext.Provider value={{ modalStates, openModal, closeModal }}>
      {children}
    </ModalsContext.Provider>
  );
};

export default ModalsProvider;
