import { Me, MeJSON } from "@/data/auth/types";
import { omitUndefinedValues } from "@/utils";

const parseMe = {
  /**
   * Parse JSON to JS.
   *
   * @param param0 - Me in JSON format.
   *
   * @returns - Me in JS format.
   */
  fromJSON: ({
    id,
    first_name: firstName,
    last_name: lastName,
    email,
    invite_code: inviteCode,
    canny_sso_token: cannySsoToken,
    number_of_invited_users: numberOfInvitedUsers,
    intercom_token: intercomToken,
    category,
    additional_fields: additionalFields,
    feature_flags: featureFlags,
  }: MeJSON): Me =>
    omitUndefinedValues({
      id,
      firstName,
      lastName,
      email,
      inviteCode,
      cannySsoToken,
      numberOfInvitedUsers,
      intercomToken,
      category,
      additionalFields,
      featureFlags,
    }),
  /**
   * Parse JS to JSON.
   *
   * @param param0 - Me in JS format.
   *
   * @returns - Me in JSON format.
   */
  toJSON: ({
    id,
    firstName: first_name,
    lastName: last_name,
    email,
    inviteCode: invite_code,
    cannySsoToken: canny_sso_token,
    numberOfInvitedUsers: number_of_invited_users,
    category,
    additionalFields: additional_fields,
    featureFlags: feature_flags,
  }: Partial<Me>): MeJSON =>
    omitUndefinedValues({
      id,
      first_name,
      last_name,
      email,
      invite_code,
      canny_sso_token,
      number_of_invited_users,
      category,
      additional_fields,
      feature_flags,
    }),
};

export default parseMe;
