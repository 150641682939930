import { api, withCancel } from "@/clients";
import { Id, QueryParams, ListPage } from "@/data/types";
import { parseQueryParams } from "@/data/parsers";
import { Workspace, WorkspaceJSON } from "@/data/workspaces/types";
import parseWorkspace from "@/data/workspaces/parse";

/**
 * Create a new workspace.
 *
 * @param workspace - Data for the new workspace.
 * @param workspace.name - Name of the new workspace.
 * @param workspace.icon - Icon color of the new workspace.
 *
 * @returns - Promise resolving to created workspace.
 */
export const create = (
  data: Pick<Workspace, "name" | "icon">
): Promise<Workspace> =>
  api
    .post(
      "/workspaces/",
      parseWorkspace.toJSON({ name: data.name, icon: data.icon })
    )
    .then(res => parseWorkspace.fromJSON(res.data));

/**
 * Update an existing workspace.
 *
 * @param id - Id of the workspace to update.
 * @param data - Data updates for the workspace.
 *
 * @returns - Promise resolving to the updated workspace.
 */
export const update = (
  id: Id,
  { name, icon }: Partial<Pick<Workspace, "name" | "icon">>
): Promise<Workspace> =>
  api
    .patch(`/workspaces/${id}/`, parseWorkspace.toJSON({ name, icon }))
    .then(res => parseWorkspace.fromJSON(res.data));

/**
 * Get page of list of workspaces user has access to.
 *
 * @param param0 - Pagination params.
 * @param param0.page - The page number to get.
 * @param param0.pageSize - The number of elements per page.
 * @param param0.ids - Filter results by ids.
 *
 * @returns - Promise resolving to page data including list of workspaces.
 */
export const fetchListPage = (
  params: Omit<QueryParams<Workspace>, "workspace">
): Promise<ListPage<Workspace>> =>
  withCancel(cancelToken =>
    api.get("/workspaces/", {
      params: parseQueryParams<Workspace, WorkspaceJSON>(
        { ...params, workspace: null },
        parseWorkspace.toJSON
      ),
      cancelToken,
    })
  ).then(({ data }) => ({
    ...data,
    results: data.results.map(parseWorkspace.fromJSON),
  }));

/**
 * Get workspace by id.
 *
 * @param id - Id of the workspace to get.
 *
 * @returns - Promise resolving to workspace.
 */
export const fetchById = (id: Id): Promise<Workspace> =>
  withCancel(cancelToken =>
    api.get(`/workspaces/${id}/`, { cancelToken })
  ).then(res => parseWorkspace.fromJSON(res.data));

/**
 * Delete a workspace by id.
 *
 * @param id - Id of the workspace to delete.
 */
export const deleteById = (id: Id): Promise<void> =>
  api.delete(`/workspaces/${id}/`);

/**
 * Invite a user to a workspace by email.
 *
 * @param workspaceId - Id of the workspace to add the user to.
 * @param email - Email of the user to invite.
 */
export const addUser = (workspaceId: Id, email: string): Promise<void> =>
  api.post(`/workspaces/${workspaceId}/add_user/`, { email });

/**
 * Remove a user from a workspace by id.
 *
 * @param workspaceId - Id of the workspace to remove the user from.
 * @param userId - Id of the user to remove from the workspace.
 */
export const removeUser = (workspaceId: Id, userId: Id): Promise<void> =>
  api.delete(`/workspaces/${workspaceId}/remove_user/`, {
    data: { user: userId },
  });

export default {
  create,
  update,
  fetchListPage,
  fetchById,
  deleteById,
  addUser,
  removeUser,
};
