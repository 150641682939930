import { useEffect, useReducer } from "react";

import { Status, View, ActionType, Category } from "./types";
import cookieConsentStateReducer from "./reducer";
import { storage } from "@/clients";

const STORAGE_KEY = "cookieConsentData";

const DefaultConsentState = {
  status: Status.UNDECIDED,
  categories: [],
};

const useCookieConsent = () => {
  const [state, dispatch] = useReducer(cookieConsentStateReducer, {}, () => {
    const restoredCookieConsentData = storage.load(
      STORAGE_KEY,
      DefaultConsentState
    );

    return {
      ...restoredCookieConsentData,
      view:
        restoredCookieConsentData.status === Status.UNDECIDED
          ? View.BANNER
          : View.FBA,
    };
  });

  useEffect(() => {
    storage.save(STORAGE_KEY, {
      status: state?.status ?? DefaultConsentState.status,
      categories: state?.categories ?? DefaultConsentState.categories,
    });
  }, [state?.status, state?.categories]);

  const handleAllowAllClick = () => {
    dispatch({ type: ActionType.ALLOW_ALL });
  };

  const handleAllowSomeClick = (categories: Array<Category>) => {
    dispatch({ type: ActionType.ALLOW_SOME, categories });
    window.location.reload();
  };

  const handleRejectAllClick = () => {
    dispatch({ type: ActionType.REJECT_ALL });
    window.location.reload();
  };

  const handleShowOptionsClick = () => {
    dispatch({ type: ActionType.SHOW_OPTIONS });
  };

  const handleHideOptionsClick = () => {
    dispatch({ type: ActionType.HIDE_OPTIONS });
  };

  return {
    state,
    handleAllowAllClick,
    handleRejectAllClick,
    handleAllowSomeClick,
    handleShowOptionsClick,
    handleHideOptionsClick,
  };
};

export default useCookieConsent;
