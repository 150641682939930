/**
 * API client.
 *
 * Client to interact with the backend server API.
 */

import axios, {
  AxiosError as ApiError,
  AxiosResponse as ApiResponse,
  CancelTokenSource,
} from "axios";
import { v4 as uuidv4 } from "uuid";

import setErrorHandlers from "@/_legacy/util/setErrorHandlers";

export const SESSION_TOKEN = "lateral:sessionId";

// Ensure the session Id is set properly
let id;
if (typeof window !== "undefined") {
  if (window.location.hash) {
    const parsedHash = new URLSearchParams(window.location.hash.substring(1));
    id = parsedHash.get("id");
    if (id) window.localStorage.setItem(SESSION_TOKEN, id);
  }
  id = window.localStorage.getItem(SESSION_TOKEN);
  if (!id) {
    id = uuidv4();
    window.localStorage.setItem(SESSION_TOKEN, id);
  }
}

const api = axios.create({
  baseURL: "/api/api",
  headers: {
    post: "{ 'Content-Type': 'application/json' }",
  },
  xsrfHeaderName: "X-CSRFTOKEN",
  xsrfCookieName: "csrftoken",
});

api.interceptors.request.use(config => {
  if (!config.url.includes("/words.json")) {
    config.headers["X-Lateral-Session"] = id;
  }
  return config;
});

setErrorHandlers(api, { authRedirect: "/login" });

const usersApi = axios.create({
  baseURL: "/api/users",
  headers: {
    post: "{ 'Content-Type': 'application/json' }",
  },
  xsrfHeaderName: "X-CSRFTOKEN",
  xsrfCookieName: "csrftoken",
});
usersApi.interceptors.request.use(config => {
  config.headers["X-Lateral-Session"] = id;
  return config;
});

setErrorHandlers(usersApi, { authRedirect: "/login" });

/**
 * Promise with a cancel method property.
 */
declare type PromiseWithCancelMethod<T> = Promise<T> & {
  /**
   * Request cancellation method that can be leveraged by react-query.
   */
  cancel: () => void;
};

/**
 * Connect axios request cancellation mechanism with react-query cancellation.
 *
 * @param callback - A callback that gets passed the axios specific `cancelToken`.
 * The callback needs to return a promise, to which a `cancel` method will be added
 * that react-query expects in case of query cancellations.
 *
 * @returns The promise that the passed callback returned with the added `cancel` method.
 */
function withCancel<T = any>(
  /* eslint-disable no-unused-vars */
  callback: (cancelToken: CancelTokenSource["token"]) => Promise<T>
  /* eslint-enable no-unused-vars */
): PromiseWithCancelMethod<T> {
  const source = axios.CancelToken.source();

  const promise = callback(source.token) as PromiseWithCancelMethod<T>;

  promise.cancel = () => {
    source.cancel("Query was cancelled by React Query");
  };

  return promise;
}

export type { ApiResponse, ApiError };

export { withCancel, usersApi };

export default api;
