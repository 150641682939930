/* eslint-disable react/display-name */

import React, { forwardRef } from "react";
import classNames from "classnames";
import type * as Polymorphic from "@radix-ui/react-polymorphic";

import "./Text.styles.scss";

/**
 * Flexible text component.
 *
 * `variant` prop corresponds to Lateral design system variants.
 *
 * @example
 * <Text as="p" variant="Body">Lorem ipsum</Text>
 *
 * @example
 * <Text as="h1" variant="Heading4">Lorem ipsum</Text>
 */
const Text = forwardRef(
  (
    { as: Component = "p", variant, color, className, ...props },
    forwardedRef
  ) => (
    <Component
      ref={forwardedRef}
      className={classNames(
        "Text",
        `Text--${variant}`,
        `Text--Color-${color}`,
        className
      )}
      {...props}
    />
  )
) as Polymorphic.ForwardRefComponent<"p", TextProps>;

Text.displayName = "Text";

Text.defaultProps = {
  variant: "Body",
  color: "Default",
};

export interface TextProps {
  /**
   * Design system style variant to apply.
   */
  variant?:
    | "ProductName"
    | "PageTitle"
    | "Heading1"
    | "Heading2"
    | "Heading3"
    | "Heading4"
    | "Body"
    | "Body2"
    | "TextLink"
    | "Meta"
    | "Inherit";
  /**
   * Override style variant color.
   */
  color?: "Default" | "Purple" | "Gray";
}

export default Text;
