import axios from "axios";
import { toast } from "react-toastify";
import Router from "next/router";
const queryString = require("query-string");

import queryClient from "@/singletons/queryClient";

/**
 * Factory function for creating error handlers.
 * @param {string} [authRedirect] - Redirect URL for 403 errors.
 * @return {function} - Error handler.
 */
function createHandleAPIError(authRedirect = null) {
  /**
   * Handles, formats and toasts request errors.
   * @param {object} error - A request error object.
   * @return {Promise} - Promise rejection with error message.
   */
  const handleAPIError = error => {
    // If it is a cancellation, ignore it
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    // redirect to login for 403 responses
    if (authRedirect && error?.response?.status === 403) {
      queryClient.clear();
      const next = encodeURIComponent(Router.asPath);
      window.location.href = `${authRedirect}?next=${next}`;

      return Promise.reject(error);
    }

    // If there is a 503 and we haven't already, reload the page
    // so the maintenance page will be rendered if it's there
    if (
      !queryString.parse(window?.location?.search)?.reloaded &&
      error?.response?.status === 503
    ) {
      var url = window.location.href;
      if (url.indexOf("?") > -1) {
        url += "&reloaded=1";
      } else {
        url += "?reloaded=1";
      }
      window.location.href = url;
      return;
    }

    let msg = "Sorry, an unknown error occurred.";

    // client errors
    if (error?.response?.status >= 400 && error?.response?.status < 500) {
      if (error?.response?.data?.message) msg = error.response.data.message;
      else if (error?.response?.data?.[0]) msg = error.response.data[0];
      else if (error?.response?.data?.detail) msg = error.response.data.detail;
      else if (error?.response?.data)
        msg = Object.values(error.response.data)?.[0]?.[0] ?? msg;
      else if (error?.request?.data) msg = JSON.stringify(error.request.data);
    } else {
      // server errors
      if (error?.message) msg = error.message;
    }

    // duplicate concept name error message
    if (
      error?.response?.status === 400 &&
      error?.response?.config?.url?.includes("snippet-types") &&
      error?.response?.data?.non_field_errors?.includes(
        "This display_name has already been used"
      )
    ) {
      msg = "A concept with this name already exists in your workspace.";
    }

    toast.error(msg);
    return Promise.reject(error);
  };

  return handleAPIError;
}

/**
 * Sets error handlers on an axios instance.
 * @param {object} api - The axios instance to be modified.
 * @param {object} [options] - Error handlers config options.
 * @param {object} [options.authRedirect] - Redirect URL for 403 errors.
 */
function setErrorHandlers(api, options = {}) {
  const { authRedirect } = options;
  const handleAPIError = createHandleAPIError(authRedirect);
  api.interceptors.response.use(null, handleAPIError);
  api.interceptors.request.use(null, handleAPIError);
}

export { createHandleAPIError };

export default setErrorHandlers;
