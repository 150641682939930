import { QueryClient } from "react-query";

import * as Sentry from "@sentry/nextjs";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      // automatic refetches do not need to be
      // so frequent for our use cases
      staleTime: 30000,
      onError: err => {
        if (
          (err as { response?: { status?: number } })?.response?.status === 404
        ) {
          Sentry.captureException(err, {
            tags: {
              reactQuery404: true,
            },
          });
        }
      },
    },
  },
});

export default queryClient;
