const omitUndefinedValues = (obj: any): any => {
  const result = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value !== "undefined") {
      result[key] = value;
    }
  });

  return result;
};

export default omitUndefinedValues;
