import React, { FC, ComponentProps } from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import classNames from "classnames";

import "./NewTooltip.styles.scss";

/**
 * Global state provider for all tooltips.
 */
const Provider = TooltipPrimitive.Provider;

Provider.displayName = "NewTooltip.Provider";

/**
 * Wrapper component for the tooltip subcomponents.
 */
const Root = TooltipPrimitive.Root;

Root.displayName = "NewTooltip.Root";

/**
 * Tooltip trigger component.
 */
const Trigger = TooltipPrimitive.Trigger;

Trigger.displayName = "NewTooltip.Trigger";

/**
 * Content of the tooltip.
 */
const Content: FC<NewTooltipProps.Content> = ({ className = "", ...props }) => (
  <TooltipPrimitive.Content
    className={classNames("NewTooltip__Content", className)}
    {...props}
  />
);

Content.displayName = "NewTooltip.Content";

/**
 * Tooltip content arrow component.
 */
const Arrow: FC<NewTooltipProps.Arrow> = ({ className = "", ...props }) => (
  <TooltipPrimitive.Arrow
    className={classNames("NewTooltip__Arrow", className)}
    {...props}
  />
);

Arrow.displayName = "NewTooltip.Arrow";

export namespace NewTooltipProps {
  export type Provider = ComponentProps<typeof TooltipPrimitive.Provider>;
  export type Root = ComponentProps<typeof TooltipPrimitive.Root>;
  export type Trigger = ComponentProps<typeof TooltipPrimitive.Trigger>;
  export type Content = ComponentProps<typeof TooltipPrimitive.Content>;
  export type Arrow = ComponentProps<typeof TooltipPrimitive.Arrow>;
}

const NewTooltip = {
  Provider,
  Root,
  Trigger,
  Content,
  Arrow,
};

export default NewTooltip;
