import { Id, QueryParams } from "@/data/types";
import { Workspace } from "@/data/workspaces/types";

const QueryKeys = {
  /**
   * Get query key for all queries related to workspaces.
   */
  all: () => ["workspaces"] as const,
  /**
   * Get query key for currently selected workspace based on URL.
   */
  current: () => ["workspaces", "current"] as const,
  /**
   * Get query key of all workspaces list queries.
   */
  lists: () => [...QueryKeys.all(), "list"] as const,
  /**
   * Get query key for specific workspaces list query.
   *
   * @param params - List query params.
   */
  list: (params: Pick<QueryParams<Workspace>, "page" | "pageSize"> = {}) =>
    [...QueryKeys.lists(), params] as const,
  /**
   * Get query key for all workspace detail queries.
   */
  details: () => [...QueryKeys.all(), "detail"] as const,
  /**
   * Get query key for specific workspace detail query.
   *
   * @param id - Id of a workspace.
   */
  detail: (id: Id) => [...QueryKeys.details(), id] as const,
};

export default QueryKeys;
