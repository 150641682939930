import * as Sentry from "@sentry/nextjs";
import { Integrations } from "@sentry/tracing";

const integrations = [new Integrations.BrowserTracing()];

Sentry.init({
  enabled:
    process.env.NODE_ENV === "production" && process.env.NEXT_PUBLIC_SENTRY_DSN,
  integrations,
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
  denyUrls: [/safari-extension:\/\//i],
  beforeSend(event, hint) {
    const error = hint.originalException;
    if (
      error &&
      error.message &&
      (error.message.match(/^Non-Error promise rejection captured with/i) ||
        error.message.match(/^Non-Error exception captured with/i))
    ) {
      return null;
    }
    return event;
  },
  environment: window.location.host,
  tracesSampleRate: 1.0,
});
