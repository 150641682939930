/**
 * Promisify waiting for a CSS transition of an element to finish.
 *
 * @param element - The element a CSS transition will be happening on.
 *
 * @returns - Promise resolving when CSS transition finished.
 *
 * @example
 * await afterTransition(elementRef.current);
 */
const afterTransition = (element: HTMLElement) =>
  new Promise<void>(resolve => {
    if (!element) {
      resolve();
    }

    const transitionDurations = getComputedStyle(element)
      .transitionDuration.split(", ")
      .map(durationText => Number(durationText.replace("s", "")) * 1000);

    const longestTransitionDuration = Math.max(...transitionDurations);

    setTimeout(() => {
      resolve();
    }, longestTransitionDuration);
  });

export default afterTransition;
