import { Status, View, State, Action, ActionType } from "./types";

const cookieConsentReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.SHOW_OPTIONS:
      return {
        ...state,
        view: View.PREFERENCES,
      };
    case ActionType.ALLOW_ALL:
      return {
        ...state,
        status: Status.ALLOWED_ALL,
        categories: [],
        view: View.FBA,
      };
    case ActionType.REJECT_ALL:
      return {
        ...state,
        status: Status.REJECTED_ALL,
        categories: [],
        view: View.FBA,
      };
    case ActionType.ALLOW_SOME:
      return {
        ...state,
        status: Status.ALLOWED_SOME,
        categories: action.categories,
        view: View.FBA,
      };
    case ActionType.HIDE_OPTIONS:
      return {
        ...state,
        view: state.status === Status.UNDECIDED ? View.BANNER : View.FBA,
      };
    default:
      return state;
  }
};

export default cookieConsentReducer;
