import { useRef, useEffect } from "react";

/**
 * Store previous value of given value on re-renders.
 * @param {*} value - The value to store.
 * @returns {*} - The previous value of the value to store.
 */
const usePreviousValue = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export default usePreviousValue;
