import { omitUndefinedValues } from "@/utils";

import { Workspace, WorkspaceJSON } from "./types";

const parseWorkspace = {
  /**
   * Parse JSON to JS.
   *
   * @param param0 - Workspace in JSON format.
   *
   * @returns - Workspace in JS format.
   */
  fromJSON: ({
    id,
    icon,
    name,
    users,
    subscribed,
    use_ocr,
    page_quota: pageQuota,
    page_quota_remaining: pageQuotaRemaining,
    ocr_quota: ocrQuota,
    ocr_quota_remaining: ocrQuotaRemaining,
    has_received_booster_quota: hasReceivedBoosterQuota,
    has_received_booster_ocr_quota: hasReceivedBoosterOcrQuota,
    created_at: createdAt,
    billing_admin: billingAdmin,
    subscription_status: subscriptionStatus,
  }: WorkspaceJSON): Workspace =>
    omitUndefinedValues({
      id,
      icon,
      name,
      users,
      subscribed,
      hasDigiDocsAccess: !use_ocr,
      pageQuota,
      pageQuotaRemaining,
      ocrQuota,
      ocrQuotaRemaining,
      hasReceivedBoosterQuota,
      hasReceivedBoosterOcrQuota,
      createdAt,
      billingAdmin,
      subscriptionStatus,
    }),
  /**
   * Parse JS to JSON.
   *
   * @param param0 - Workspace in JS format.
   *
   * @returns - Workspace in JSON format.
   */
  toJSON: ({
    id,
    icon,
    name,
    users,
    subscribed,
    hasDigiDocsAccess,
    pageQuota,
    pageQuotaRemaining,
    ocrQuota,
    ocrQuotaRemaining,
    hasReceivedBoosterQuota,
    hasReceivedBoosterOcrQuota,
    createdAt,
    billingAdmin,
    subscriptionStatus,
  }: Partial<Workspace>): WorkspaceJSON =>
    omitUndefinedValues({
      id,
      icon,
      name,
      users,
      subscribed,
      use_ocr: hasDigiDocsAccess ? !hasDigiDocsAccess : undefined,
      page_quota: pageQuota,
      page_quota_remaining: pageQuotaRemaining,
      ocr_quota: ocrQuota,
      ocr_quota_remaining: ocrQuotaRemaining,
      has_received_booster_quota: hasReceivedBoosterQuota,
      has_received_booster_ocr_quota: hasReceivedBoosterOcrQuota,
      created_at: createdAt,
      billing_admin: billingAdmin,
      subscription_status: subscriptionStatus,
    }),
};

export default parseWorkspace;
