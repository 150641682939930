export default {
  id: 469,
  filename: "Supply-Agreement.pdf.pdf",
  size: 255381,
  page_count: 7,
  created_at: "2021-06-10T12:09:21.674095Z",
  pdf:
    "https://storage.googleapis.com/lateral-dev-customer-files/5359c353-3c20-4555-83fc-06339a37741d/document.pdf?response-content-disposition=attachment%3B%20filename%3D%22Supply-Agreement.pdf.pdf%22&response-content-type=application%2Fpdf&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=GOOG1EHI6Z3FOPDYE3UAQDJNRGMV3IPENKURTJ5FFODQCIAAGWU4GG5GEPHFI%2F20210615%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20210615T111049Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=14e7a933d601f1cb0dce1ea8963ca2baa00d7d2da55173a113821642fdf761f0",
  hash: "869a321785a6b106f470b2c336541419",
  snippet_type_count: 0,
  labels: [],
  status: "AVAILABLE",
  failure_reason: "",
  dst_id: 296,
  trained: false,
  first_page:
    "https://storage.googleapis.com/lateral-dev-customer-files/5359c353-3c20-4555-83fc-06339a37741d/page_0001_low.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=GOOG1EHI6Z3FOPDYE3UAQDJNRGMV3IPENKURTJ5FFODQCIAAGWU4GG5GEPHFI%2F20210615%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20210615T111049Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=efca74ec063b06e5eff3965df179e4221b1b9998565d19b7c49b23cbe1faff4a",
  thumbnails: [
    "https://storage.googleapis.com/lateral-dev-customer-files/5359c353-3c20-4555-83fc-06339a37741d/page_0001.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=GOOG1EHI6Z3FOPDYE3UAQDJNRGMV3IPENKURTJ5FFODQCIAAGWU4GG5GEPHFI%2F20210615%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20210615T111049Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=50c9cb5a0c689825992c138d80aa547fc4eb66eeb6dfe783dfdb44d653019771",
    "https://storage.googleapis.com/lateral-dev-customer-files/5359c353-3c20-4555-83fc-06339a37741d/page_0002.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=GOOG1EHI6Z3FOPDYE3UAQDJNRGMV3IPENKURTJ5FFODQCIAAGWU4GG5GEPHFI%2F20210615%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20210615T111049Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=073839f5331045761844c51e403e8961074305b268b6992be6029bb3595e0dcc",
    "https://storage.googleapis.com/lateral-dev-customer-files/5359c353-3c20-4555-83fc-06339a37741d/page_0003.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=GOOG1EHI6Z3FOPDYE3UAQDJNRGMV3IPENKURTJ5FFODQCIAAGWU4GG5GEPHFI%2F20210615%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20210615T111049Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=531e8acd4da5c2300f2f252acfa3c51b45dfdca0dcc2c508c4b1d3ce258ab122",
    "https://storage.googleapis.com/lateral-dev-customer-files/5359c353-3c20-4555-83fc-06339a37741d/page_0004.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=GOOG1EHI6Z3FOPDYE3UAQDJNRGMV3IPENKURTJ5FFODQCIAAGWU4GG5GEPHFI%2F20210615%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20210615T111049Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=3fa67a2a8611fb7a8503e559bd80aaaff72a550515694c7a9fe99ccb650d74e2",
    "https://storage.googleapis.com/lateral-dev-customer-files/5359c353-3c20-4555-83fc-06339a37741d/page_0005.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=GOOG1EHI6Z3FOPDYE3UAQDJNRGMV3IPENKURTJ5FFODQCIAAGWU4GG5GEPHFI%2F20210615%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20210615T111049Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=7f4174842f70363cb9b2160243ae9364e83866dc4fdc979d62e634797051c3cb",
    "https://storage.googleapis.com/lateral-dev-customer-files/5359c353-3c20-4555-83fc-06339a37741d/page_0006.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=GOOG1EHI6Z3FOPDYE3UAQDJNRGMV3IPENKURTJ5FFODQCIAAGWU4GG5GEPHFI%2F20210615%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20210615T111049Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=9a5162d679c585c9a0c0e07bf2238dfb3567696057f9009a87b083c8821e3f69",
    "https://storage.googleapis.com/lateral-dev-customer-files/5359c353-3c20-4555-83fc-06339a37741d/page_0007.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=GOOG1EHI6Z3FOPDYE3UAQDJNRGMV3IPENKURTJ5FFODQCIAAGWU4GG5GEPHFI%2F20210615%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20210615T111049Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=47402fd4b772921b5226aa3aab4a70e660174a9a898a9ee48da26c7eff4de6a0",
  ],
};
