import { State, Action, ActionType, ModalState } from "@/contexts/Modals/types";

/**
 * Reducer controlling global modals state logic.
 *
 * @param state - The current modals states.
 * @param action - An action that should trigger a state change.
 *
 * @returns - The new state based on the action.
 */
const modalsReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.OPEN_MODAL:
      // make sure only one modal is open at a time
      return Object.fromEntries(
        Object.entries(state).map(([modalId]) => [
          modalId,
          modalId === action.modalId ? ModalState.OPEN : ModalState.CLOSED,
        ])
      );
    case ActionType.CLOSE_MODAL:
      return {
        ...state,
        [action.modalId]: ModalState.CLOSED,
      };
    default:
      return state;
  }
};

export default modalsReducer;
