/* eslint-disable no-unused-vars */

export enum ActionType {
  OPEN_MODAL = "OPEN_MODAL",
  CLOSE_MODAL = "CLOSE_MODAL",
}

export enum ModalId {
  GET_MORE_PAGES = "GET_MORE_PAGES",
  REFER_A_FRIEND = "REFER_A_FRIEND",
  WELCOME_VIDEO = "WELCOME_VIDEO",
  PRICES = "PRICES",
  ACADEMIC_DISCOUNT = "ACADEMIC_DISCOUNT",
}

export enum ModalState {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}

export type State = {
  [key in ModalId]: ModalState;
};

export interface Action {
  type: ActionType;
  modalId: ModalId;
}

export type ContextValue = {
  openModal: (modalId: ModalId) => void;
  closeModal: (modalId: ModalId) => void;
  modalStates: {
    [key in ModalId]: {
      state: ModalState;
      isOpen: boolean;
      isClosed: boolean;
    };
  };
};
